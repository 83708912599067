import React, { createRef } from 'react';
import '../App.css';
import { fetchOrganizations, uploadLogo, OrgbaseApiUrl, uploadHeaderColor } from '../api/api';
import './ConfigureOrg.css';
import LoaderComponent from './LoaderComponent';
import Config from './ConfigureOrgConfig.json';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PhotoshopPicker } from 'react-color';
import Modal from 'react-modal';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { connect } from 'react-redux';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const initialState = {
    Organizations: [],
    userID: '',
    prompt: false,
    startBill: false,
    Org_Name: '',
    Org_Type: null,
    is_prediction_available: false,
    ConfigType: '1',
    Org_Id: '',
    logoPath: '',
    color: '#fff',
    isOpen: false,
    header_color: '',
    isLoading: false,
    isDisplaylogo: true,
    selectedConfigType: '1',
    selectedConfigLabel: 'Client',
    selectedSubConfigType: '',
    selectedSubConfigLabel: '',
    referral_info: [],
    configMenu: []
};

class ConfigureOrg extends React.Component {
    constructor() {
        super();
        this.imageUploader = createRef();
        this.uploadedImage = createRef();
        this.state = initialState;
    }


    async componentDidMount() {
        const { location, history } = this.props;
        this.setState({ isLoading: true });
        const res = await fetchOrganizations();
        this.setState({
            Organizations: res,
            isLoading: false
        });
        if (location && location.state && location.state.id) {
            let id = this.props.location.state.id;
            history.replace({ state: {} });
            this.getOrganizationDetails(res, id);

        }
    }

    handleClose = () => {
        this.setState({
            isOpen: false,
            startBill: false,
            header_color: this.state.color
        });
    };

    handleShow = () => {
        this.setState({
            isOpen: true
        });
    };

    handleChange = async (e) => {
        e.preventDefault();
        const id = e.target.value;
        if (id?.toString() !== '') {
            this.setState({ isLoading: true });
            await this.getOrganizationDetails(this.state.Organizations, id)
            this.setState({ isLoading: false });
        } else {
            initialState.Organizations = this.state.Organizations;
            this.setState({ ...initialState });
        }

    };

    getOrganizationDetails = (state, id) => {
        const org_details = state.find((org) => org.id?.toString() === id?.toString());
        this.setState({
            Org_Name: org_details.org_name,
            Org_Type: org_details.org_type,
            Org_Id: org_details.id,
            logoPath: org_details.logo_path,
            color: org_details.header_color,
            header_color: org_details.header_color,
            is_prediction_available: org_details.is_prediction_available,
            selectedConfigType: org_details.referral_info[0].config_type,
            selectedConfigLabel: org_details.referral_info[0].config_name,
            selectedSubConfigType: org_details.referral_info[0].referral_forms[0].sub_config_type,
            selectedSubConfigLabel: org_details.referral_info[0].referral_forms[0].label,
            referral_info: org_details.referral_info

        }, () => this.filteredMenu(org_details.referral_info[0].config_type, org_details.referral_info[0].referral_forms[0].label));
    }

    filteredMenu = (configType, selectedLabel) => {
        let menu = Config.Config_Menu.filter((item) => item.configType.includes(configType));

        if (menu.length > 0) { menu[0].label = `${selectedLabel} Questions` }

        if (menu.length > 0 && menu[0].label === "Family Questions" && menu[0].configType.includes(configType)) {
            menu[0].label = `${selectedLabel} Questions`
        }
        this.setState({
            configMenu: menu
        })
    }

    fileChangedHandler = (e) => {
        let [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            let { current } = this.uploadedImage;
            current.file = file;

            reader.onload = (e) => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }

        this.setState({ selectedFile: e.target.files[0] });
    };

    uploadHandler = async (e) => {
        e.preventDefault();
        let org_id = this.state.Org_Id;
        var formdata = new FormData();
        formdata.append('customer', org_id);
        formdata.append('logo', this.state.selectedFile, this.state.selectedFile.name);
        const response = await uploadLogo(formdata);
        if (response.message === 'logo uploaded successfully') {
            const res = await fetchOrganizations();
            let Org_Details = res.find((p) => p.id === org_id);
            this.setState({
                logoPath: Org_Details.logo_path
            });
            toast.info(`Logo uploaded successfully.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });

            //     window.location.reload(true);
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    selectColor = async (e) => {
        let org_id = this.state.Org_Id;
        let { header_color } = this.state;
        this.setState({
            isOpen: false
        });
        const response = await uploadHeaderColor(org_id, header_color);
        if (response.message === 'header color saved successfully') {
            toast.info(`Color updated successfully.`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
    };

    onSelectLogo = (e) => {
        let value = e.target.value;

        this.setState({ isDisplaylogo: value === "1" })
    }

    onSelectConfigType = (e) => {
        let value = e.target.value;
        value = JSON.parse(value);

        this.setState({
            selectedConfigType: value.config_type,
            selectedConfigLabel: value.config_name,
            selectedSubConfigType: value.referral_forms[0].sub_config_type,
            selectedSubConfigLabel: value.referral_forms[0].label
        },
            () => this.filteredMenu(value.config_type, value.referral_forms[0].label))
    }

    onSelectSubConfigType = (e) => {
        let value = e.target.value;
        value = JSON.parse(value);

        if (value) {
            this.setState({
                selectedSubConfigType: value.sub_config_type,
                selectedSubConfigLabel: value.label
            },
                () => this.filteredMenu(this.state.selectedConfigType, value.label))
        }
    }

    render() {
        toast.configure();
        const { Org_Name, Org_Type, Org_Id, referral_info, header_color, isLoading, configMenu, isDisplaylogo, selectedConfigType, selectedSubConfigType } = this.state;
        
        return (
            <div className="container-fluid">
                <LoaderComponent isLoading={isLoading} />
                <form>
                    <div className="form-group d-flex flex-column bd-highlight mb-2">
                        <div className="row p-2 bg-primary text-white">{Org_Name ? `Configure FirstMatch® Tool for ${Org_Name}:` : 'Choose an organization:'}</div>
                        <div className="form-group row mt-4 mb-4">
                            <label className="col-sm-4 col-form-label font-weight-bold ">Select Organization to Configure:</label>
                            <div className="col-sm-3">
                                <select name="group_id" className="form-control" id="exampleFormControlSelect1" onChange={this.handleChange} required>
                                    <option value="">Select Organization</option>
                                    {this.state.Organizations &&
                                        this.state.Organizations?.sort((a, b) => a.org_name.localeCompare(b.org_name)).map((org, i) => (
                                            <option key={i} value={org.id} selected={this.state.Org_Id == org.id}>
                                                {org.org_name}
                                            </option>
                                        ))}
                                </select>
                            </div>
                            {this.state.group_idError && <div className="text-center text-danger">{this.state.group_idError}</div>}
                        </div>
                        {Org_Name &&
                            <div class="container">
                                <div class="section section-tiles">
                                    {referral_info.length > 1 && (
                                        <React.Fragment>
                                            <label class="section-label">Select Configure Type:</label>
                                            <select class="section-dropdown" onClick={this.onSelectConfigType}>
                                                {referral_info.map((conf_list, ind_opt) => (
                                                    <option key={ind_opt} value={JSON.stringify(conf_list)}>{conf_list.config_name}</option>
                                                ))}
                                            </select>
                                            <br />
                                        </React.Fragment>
                                    )}
                                    {referral_info.find((item) => item.config_type === selectedConfigType)?.referral_forms.length > 1 && (
                                        <React.Fragment>
                                            <label class="font-weight-bold">Select Sub-Configure Type:</label>
                                            <select onClick={this.onSelectSubConfigType}>
                                                {referral_info.map((conf_list) =>
                                                    (selectedConfigType == conf_list.config_type) &&
                                                    conf_list.referral_forms.map((subConf) => (
                                                        <option key={subConf.sub_config_type} value={JSON.stringify(subConf)}>
                                                            {subConf.label}
                                                        </option>
                                                    ))
                                                )}
                                            </select>

                                            <br />
                                        </React.Fragment>
                                    )}

                                    {configMenu.map((menu, index) => menu.configType.includes(selectedConfigType) && (
                                        <Link to={`/admin/${menu.path}/Org=${Org_Name}&id=${Org_Id}&Config=${selectedConfigType}&subConfig=${selectedSubConfigType}`}>
                                            <div class="tile"><strong className="px-2">{menu.label}</strong></div>
                                        </Link>
                                    ))}
                                </div>


                                <div class="section section-radio">
                                    <label class="section-label">Select Logo/Color:</label>
                                    <select class="section-dropdown" onClick={(e) => this.onSelectLogo(e)} >
                                        <option value="1">Upload Logo</option>
                                        <option value="2">Pick Color</option>
                                    </select>




                                    {isDisplaylogo ? (
                                        <React.Fragment>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={this.fileChangedHandler}
                                                ref={this.imageUploader}
                                                style={{
                                                    display: 'none'
                                                }}
                                            />
                                            <i
                                                className='image-container'
                                                onClick={() => this.imageUploader.current.click()}
                                            >
                                                <img
                                                    ref={this.uploadedImage}
                                                    src={`${OrgbaseApiUrl}/${this.state.logoPath}`}
                                                />
                                            </i>


                                            <span className="text-center">Click on above to select an Image</span>


                                            <span className="text-danger small">Logo should be in PNG/JPEG format and size should be less or equal to 10kb. 120X40 px - Ideal size</span>



                                            <button className="btn btn-primary" onClick={this.uploadHandler}>
                                                Upload
                                            </button>


                                            {Org_Type && Org_Type === 2 ? (
                                                <div className="row mt-2">
                                                    <div className="col col-2">Or</div>
                                                    <div className="col col-4">
                                                        <button className="btn btn-primary" type="button">
                                                            Enter a Text
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <strong>Pick a Color:</strong>
                                            <p
                                                onClick={this.handleShow}
                                                style={{
                                                    alignSelf: 'center',
                                                    backgroundColor: header_color,
                                                    height: '100%',
                                                    color: '#fff',
                                                    width: '50%',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                            </p>
                                            <span >Click on above to pick a Color for Organization.</span> <br />
                                            {/* Selected Color is <strong> {header_color}</strong> */}
                                        </React.Fragment>
                                    )}







                                </div>
                            </div>



                        }
                    </div>

                </form>
                <Modal
                    isOpen={this.state.isOpen}
                    //   onAfterOpen={afterOpenModal}
                    onRequestClose={this.handleClose}
                    style={customStyles}
                    contentLabel="Forgot Password"
                    ariaHideApp={false}
                >
                    <div className="row">
                        <div className="col col-3" style={{ alignContent: 'center' }}>
                            <PhotoshopPicker color={this.state.header_color} onChange={(updatedColor) => this.setState({ header_color: updatedColor.hex })} onAccept={this.selectColor} onCancel={this.handleClose} />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.loginData.user
    };
};

export default connect(mapStateToProps)(ConfigureOrg);
