import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoaderComponent from '../LoaderComponent';

export const UploadDependentAnswers = (props) => {
    const { sections, sectionIndex, questionIndex } = props;
    toast.configure();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [filterdSections, setFilteredSections] = useState(sections);
    const [dependencies, setDependencies] = useState(['']);

    const fileRef = useRef();

    const non_filter_ques = sections[sectionIndex].questions[questionIndex].question;
    const selected_question = sections[sectionIndex].questions[questionIndex];

    useEffect(() => {
        let deciders_obj = sections[sectionIndex].questions[questionIndex]?.deciders_obj?.deciders || [];
        let file_path = sections[sectionIndex].questions[questionIndex]?.deciders_obj?.file_path;
        let result = sections.flatMap((section) => section.questions.filter((question) => ['SELECT', 'RADIO'].includes(question.answer_type) && question.question !== non_filter_ques));
        setDependencies(deciders_obj);
        setFilteredSections(result);
        setSelectedFile(file_path);
    }, []);

    const addRow = () => {
        setDependencies([...dependencies, '']);
    };

    function removeRow(index) {
        dependencies.splice(index, 1);
        
        setDependencies([...dependencies]);
    }

    const handleSelectChange = (index) => (evnt) => {
        evnt.stopPropagation();
        const { value } = evnt.target;

        const list = [...dependencies];
        list[index] = value;
        setDependencies(list);
    };

    const downloadTemplate = (e) => {
        e.preventDefault();
        let questionId = sections[sectionIndex].questions[questionIndex].id;
        let { id, Config, subConfig } = props.match.params;
        let data = {
         customer: id,
         config: Config,
         subConfig: subConfig,
         question_id: questionId,
         deciders: dependencies?.toString()
        }
        
        props.downloadTemplate(data, function(result){
            
            if(result.status === 'success') {
                toast.info('File downloaded successfully.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } else {
                toast.error(result?.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
        })
    }

   const uploadDependencies = (e) => {
    e.preventDefault();
        let questionId = sections[sectionIndex].questions[questionIndex].id;
        let { id, Config, subConfig } = props.match.params;
        const formData = new FormData();
        formData.append('customer', id);
        formData.append('config_type', Config);
        formData.append('sub_config_type', subConfig);
        formData.append('question_id', questionId);
        formData.append('deciders_config_file', selectedFile);
        
        props.uploadTemplate(formData, function(result){
            if(result.status === 'success') {
                sections[sectionIndex].questions[questionIndex].deciders_obj = {"file_path": result.response, "deciders": dependencies};
                setSelectedFile(result.response);
                props.updateState(sections)
                toast.info('Deciders config uploaded successfully.', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } else {
                toast.error(result?.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
        })

   }

   const downloadConfiguredFile = (e) => {
    e.preventDefault();
        let questionId = sections[sectionIndex].questions[questionIndex].id;
        let { id, Config, subConfig } = props.match.params;
        let data = {
         customer: id,
         config: Config,
         subConfig: subConfig,
         question_id: questionId
        }
        
        props.downloadConfigure(data, function(result){
            if(result.status === 'success') {
                toast.info('File downloaded successfully', {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            } else {
                toast.error(result?.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000
                });
            }
        })
   }

   function isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }

    const onFileChange = (e) => {
        e.stopPropagation();
        let file = e.target.files[0];
        
        if(file.type === 'text/csv') {
            setSelectedFile(file);
        } else {
            toast.error('Please upload .csv file.', {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            });
        }
        
 };

    
    return (
        <Fragment>
            <label className="form-label text-primary"> Select below Dependent Questions to filter answers:</label>
            {dependencies.map((item, index) => (
                <div className="form-group field-row" style={{ marginBottom: '2px', display: 'flex' }} key={index}>
                    <label className="col-sm-4 col-form-label font-weight-bold "> Dependent Question {index + 1}:</label>
                    <div className="col-sm-4">
                        <select name="question" className="form-control" onChange={handleSelectChange(index)} required>
                            <option value="">Select Question</option>
                            {filterdSections.map((list, i) => {
                               return (
                                     <option key={i} value={list.id} selected={Number(item) === list.id}>
                                        {list.question}
                                    </option>
                                );
                            })}
                        </select>
                        
                        {index !== 0 && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '1px',
                                    right: '-15px',
                                    width: '25px',
                                    height: '25px',
                                    fontSize: '24px',
                                    color: 'red'
                                }}
                                className="font-text-bold text-center "
                                onClick={() => removeRow(index)}
                            >
                                <i className="fa fa-remove"></i>
                               
                            </div>
                        )}
                        {dependencies.length - 1 === index && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '1px',
                                    right: '-40px',
                                    width: '25px',
                                    height: '25px',
                                    fontSize: '24px'
                                }}
                                className="font-text-bold text-center "
                                onClick={() => addRow()}
                            >
                                <i
                                    className="fa fa-plus"
                                    style={{
                                        fontSize: '28px'
                                    }}
                                ></i>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className="form-group field-row" style={{ margin: '4px 4px' }}>
                {dependencies.some((item) => item !== '') && (
                    <React.Fragment>
                    <div className="col-sm-4">
                    <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                            marginRight: 10,
                            backgroundColor: '#007bff',
                            color: '#fff'
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Download .CSV template file of dependent questions."
                        onClick={(e) => downloadTemplate(e)}
                    >
                        Download Template
                    </Button>
                </div>
                <div className="col-sm-4">
                <input name="uploadfile" ref={fileRef} type="file" onChange={onFileChange} />
                <small>{isObject(selectedFile) ? "" : selectedFile}</small>
                {selectedFile && ( 
                    <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    style={{
                        marginRight: 10,
                        backgroundColor: '#007bff',
                        color: '#fff'
                    }}
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Upload configured .CSV file of dependent questions."
                    onClick={(e) => uploadDependencies(e)}
                >
                    Upload
                </Button>
                )}
                
            </div>
            </React.Fragment>
                )}
                {selected_question?.deciders_obj?.file_path !== '' && (
                    <div className="col-sm-4">
                    <Button
                        type="submit"
                        size="small"
                        variant="contained"
                        style={{
                            marginRight: 10,
                            backgroundColor: '#007bff',
                            color: '#fff'
                        }}
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Download configured .CSV file  of dependent questions."
                        onClick={(e) => downloadConfiguredFile(e)}
                    >
                        Download File
                    </Button>
                </div>
                )}
                
                
            </div>

            <LoaderComponent isLoading={isLoading} />
        </Fragment>
    );
};
