import React, { useEffect, useRef, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Modal,
    Button,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    TextField,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@material-ui/core';
import { UpdateMappingFields } from '../../api/api';
import { toast } from 'react-toastify';
import { Autocomplete } from '@material-ui/lab';

const MappingFieldsOverLay = ({ isOpen, mappingFields, close, callBack }) => {
    const [open, setOpen] = React.useState(isOpen);
    const handleClose = () => close(false);
    const [mappingRows, setMappingRows] = useState([]);
    const [fileFieldRows, setFileFieldRows] = useState([]);
    const staticData = {
        ConfiguredFields: ['Name', 'LastName', 'Gender', 'Address', 'Mobile', 'Address', 'Mobile'],
        FileFields: ['Name', 'LastName', 'Gender', 'Address', 'Mobile']
    };

    const descriptionElementRef = React.useRef();

    // useEffect(() => {
    //     setOpen(isOpen);
    // }, [isOpen]);

    const save = () => {

        let mappingSet = {};
        mappingRows.map((item) => {
            mappingSet[item.Name] = item.MappedField;
        });
        let { Config, subConfig } = this.props.match.params;
        let formData = new FormData();
        formData.append("mapping_set", JSON.stringify(mappingSet));
        formData.append("transaction_id", mappingFields.TransactionId);
        formData.append("customer", mappingFields.Customer);
        formData.append("config_type", Config);
        formData.append("sub_config_type", subConfig);

        UpdateMappingFields(formData, function (result) {
            console.log(result, "result");

            if (result.status === "success") {
                close(false);
                if (callBack)
                    callBack();
                toast.info(`Mappings Saved Successfully.`, { position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            } else
                toast.error(result.message, { position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
        });
    }

    useEffect(() => {
        if (mappingFields && mappingFields.ConfiguredFields && mappingFields.FileFields) {
            var result = mappingFields.ConfiguredFields?.map((item, index) => {
                return {
                    Id: index,
                    Name: item,
                    MappedField: mappingFields.MappingSet ? mappingFields.MappingSet[item] : ''
                };
            });

            setMappingRows(result);

            setFileFieldRows(mappingFields.FileFields);
        }
    }, []);


    const handleChange = (event, val, row) => {
        var temp = [...mappingRows];

        temp.map((item) => {
            if (item.Id == row.Id) {
                item.MappedField = val || '';
            }
        });

        setMappingRows(temp);
    };

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                animation="false"
            >
                <DialogTitle id="scroll-dialog-title">MappedFields</DialogTitle>
                <DialogContent dividers='paper'>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                        style={{ minWidth: '55vw' }}
                        ref={descriptionElementRef}
                    >
                        <TableContainer component={Paper}>
                            <Table aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <b>Configured Fields</b>
                                        </TableCell>
                                        <TableCell>
                                            <b>File Fields</b>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {mappingRows.map((row) => (
                                        <TableRow key={row.Id}>
                                            <TableCell style={{ padding: '5px 15px' }} component="td" scope="row">
                                                {row.Name}
                                            </TableCell>
                                            <TableCell style={{ padding: '5px 15px' }}>
                                                <FormControl style={{ width: '220px', textAlign: 'left' }} size="small">
                                                    {/*<InputLabel id="demo-simple-select-label">Select Field</InputLabel>
                                                     <Select
                                                        labelId="demo-simple-select-label"
                                                        value={row.MappedField}
                                                        label="Select Field"
                                                        onChange={(e) => handleChange(e, row)}
                                                    >
                                                        {fileFieldRows.map((field) => (
                                                            <MenuItem value={field}>{field}</MenuItem>
                                                        ))}
                                                    </Select> */}
                                                    <Autocomplete
                                                        disablePortal
                                                        options={fileFieldRows}
                                                        value={row.MappedField}
                                                        sx={{ width: 300 }}
                                                        onChange={(e, val) => handleChange(e, val, row)}
                                                        renderInput={(params) => <TextField {...params} label="Select Field" />}
                                                    />
                                                </FormControl>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='btnStyle' onClick={handleClose}>Close</Button>
                    <Button className='btnStyle' onClick={save}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default MappingFieldsOverLay;
